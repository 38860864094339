import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Question = ({ question, pIndex, qIndex, updateQuestion, removeQuestion }) => {
    const handleQuestionChange = content => {
        updateQuestion(qIndex, { ...question, question: content });
    };

    const handleOptionChange = (optionIndex, newValue) => {
        const newOptions = question.options.map((option, idx) =>
            idx === optionIndex ? newValue : option
        );
        updateQuestion(qIndex, { ...question, options: newOptions });
    };

    const addOption = () => {
        updateQuestion(qIndex, { ...question, options: [...question.options, ''] });
    };

    const removeOption = optionIndex => {
        const newOptions = question.options.filter((_, idx) => idx !== optionIndex);
        updateQuestion(qIndex, { ...question, options: newOptions });
    };

    return (
        <div className='mb-2'>
            <label className='form-label'>Question {qIndex + 1}:</label>
            <ReactQuill
                value={question.question}
                onChange={handleQuestionChange}
                theme='snow'
                modules={{
                    toolbar: [
                        [{ font: [] }],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ color: [] }, { background: [] }],
                        [{ script: 'sub' }, { script: 'super' }],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ indent: '-1' }, { indent: '+1' }],
                        [{ direction: 'rtl' }],
                        [{ align: [] }],
                        ['link', 'image', 'video'],
                        ['clean'],
                    ],
                }}
                formats={[
                    'font', 'header', 'bold', 'italic', 'underline', 'strike',
                    'color', 'background', 'script', 'list', 'bullet',
                    'indent', 'direction', 'align', 'link', 'image', 'video',
                ]}
            />
            {question.type === 'multiple-choice' && (
                <div className='mt-2'>
                    {question.options.map((option, idx) => (
                        <div key={idx} className='mb-2 d-flex align-items-center'>
                            <label className='form-label me-2'>
                                {String.fromCharCode(65 + idx)}:
                            </label>
                            <ReactQuill
                                value={option}
                                onChange={content => handleOptionChange(idx, content)}
                                theme='snow'
                            />
                            <button
                                className='btn btn-danger btn-sm'
                                style={{ height: '30px', width: '170px', margin: '3px' }}
                                onClick={() => removeOption(idx)}
                            >
                                Remove Option
                            </button>
                        </div>
                    ))}
                    <button className='btn btn-secondary btn-sm' onClick={addOption}>
                        Add Option
                    </button>
                </div>
            )}
            <button className='btn btn-danger btn-sm mt-2' onClick={() => removeQuestion(qIndex)}>
                Remove Question
            </button>
        </div>
    );
};

export default Question;
