import React, { useState, useEffect } from 'react';
import Passage from './OnlineExamPassages';

const Section = ({ section, index, updateSection, removeSection }) => {
    const [selectedPassageIndex, setSelectedPassageIndex] = useState(0);
    const [shouldRenderPassage, setShouldRenderPassage] = useState(false);

    // ✅ Ensure first passage is selected and setTimeout is applied only on mount
    useEffect(() => {
        if (section.passages.length > 0) {
            setSelectedPassageIndex(0);
            setShouldRenderPassage(false);

            setTimeout(() => {
                setShouldRenderPassage(true);
            }, 500);
        }
    }, [section.passages]); // ✅ Re-render on passage change

    const handleTimeLimitChange = (e) => {
        updateSection(index, { ...section, timeLimit: e.target.value });
    };

    const addPassage = () => {
        const newPassage = { id: Date.now(), passage: '', questions: [] };

        updateSection(index, {
            ...section,
            passages: [...(section.passages || []), newPassage]
        });

        setSelectedPassageIndex(section.passages.length);
        setShouldRenderPassage(false);

        setTimeout(() => {
            setShouldRenderPassage(true);
        }, 500);
    };

    const updatePassage = (pIndex, newPassage) => {
        updateSection(index, {
            ...section,
            passages: section.passages.map((p, i) => (i === pIndex ? newPassage : p))
        });
    };

    const removePassage = (pIndex) => {
        const updatedPassages = section.passages.filter((_, i) => i !== pIndex);
        updateSection(index, { ...section, passages: updatedPassages });

        setSelectedPassageIndex(prevIndex => Math.max(0, Math.min(prevIndex, updatedPassages.length - 1)));
        setShouldRenderPassage(false);

        setTimeout(() => {
            setShouldRenderPassage(updatedPassages.length > 0);
        }, 500);
    };

    const handlePassageSelectionChange = (e) => {
        setSelectedPassageIndex(Number(e.target.value));
        setShouldRenderPassage(false);

        setTimeout(() => {
            setShouldRenderPassage(true);
        }, 500);
    };

    return (
        <div className='border p-3 mb-4'>
            <h2>Section {index + 1}</h2>

            {/* Time Limit Input */}
            <label>Time Limit (Minutes):</label>
            <input
                type='number'
                className='form-control mb-2'
                value={section.timeLimit}
                onChange={handleTimeLimitChange}
                min="1"
            />

            {/* Passage Selection Dropdown */}
            {section.passages.length > 0 && (
                <select
                    value={selectedPassageIndex}
                    onChange={handlePassageSelectionChange}
                    className='form-select mb-3'
                >
                    {section.passages.map((passage, pIndex) => (
                        <option key={passage.id} value={pIndex}>
                            Passage {pIndex + 1}
                        </option>
                    ))}
                </select>
            )}

            {/* Render Only Selected Passage with Delayed Rendering */}
            {shouldRenderPassage && section.passages.length > 0 && (
                <Passage
                    passage={section.passages[selectedPassageIndex]}
                    index={selectedPassageIndex}
                    updatePassage={updatePassage}
                    removePassage={removePassage}
                />
            )}

            <button className='btn btn-primary mt-2' onClick={addPassage}>Add Passage</button>
            <button className='btn btn-danger mt-2 ms-2' onClick={() => removeSection(index)}>Remove Section</button>
        </div>
    );
};

export default Section;