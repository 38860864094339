import React, { useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Question from './OnlineExamQuestions';  // Import Question component

const Passage = ({ passage, index, updatePassage, removePassage }) => {
    const isFirstRender = useRef(false);

    console.log("Working with passage:", index);

    const updateQuestion = (qIndex, newQuestion) => {
        updatePassage(index, {
            ...passage,
            questions: passage.questions.map((q, idx) => (idx === qIndex ? newQuestion : q)),
        });
    };

    const addQuestion = type => {
        const newQuestion =
            type === 'multiple-choice'
                ? { type, question: '', options: [''] }
                : { type, question: '', answer: '' };
        updatePassage(index, {
            ...passage,
            questions: [...passage.questions, newQuestion],
        });
    };

    const removeQuestion = qIndex => {
        updatePassage(index, {
            ...passage,
            questions: passage.questions.filter((_, idx) => idx !== qIndex),
        });
    };

    const handlePassageChange = content => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        updatePassage(index, { ...passage, passage: content });
    };

    return (
        <div className='mb-4 border p-3'>
            <h3>Passage {index + 1}</h3>
            <button className='btn btn-danger btn-sm mb-3' onClick={() => removePassage(index)}>
                Remove Passage
            </button>
            <div className='mb-3'>
                <label className='form-label'>Passage Text:</label>
                <ReactQuill
                    value={passage.passage}
                    onChange={handlePassageChange}
                    theme='snow'
                />
            </div>

            {/* Now `Question` is properly recognized */}
            {passage.questions.map((question, qIndex) => (
                <Question
                    key={`question-${qIndex}`}
                    question={question}
                    pIndex={index}
                    qIndex={qIndex}
                    updateQuestion={updateQuestion}
                    removeQuestion={removeQuestion}
                />
            ))}

            <div>
                <button
                    className='btn btn-primary btn-sm me-2'
                    onClick={() => addQuestion('multiple-choice')}
                >
                    Add Multiple Choice Question
                </button>
                <button
                    className='btn btn-primary btn-sm'
                    style={{ margin: '8px' }}
                    onClick={() => addQuestion('free-response')}
                >
                    Add Free Response Question
                </button>
            </div>
        </div>
    );
};

export default Passage;
