import React, { useState, useEffect } from 'react';
import Section from './OnlineExamSections';
import 'react-quill/dist/quill.snow.css';

const TestBuilder = ({ sections, setSections }) => { 
    const [showSections, setShowSections] = useState({});
    const [selectedPassages, setSelectedPassages] = useState({}); // ✅ Track selected passage per section

    // ✅ Initialize selected passages on mount
    useEffect(() => {
        const initialSelections = {};
        sections.forEach(section => {
            if (section.passages.length > 0) {
                initialSelections[section.id] = 0; // Select first passage if available
            }
        });
        setSelectedPassages(initialSelections);
    }, [sections]);

    const addSection = () => {
        const newSection = { id: Date.now(), timeLimit: 30, passages: [] };
        setSections([...sections, newSection]);
        setShowSections({ ...showSections, [newSection.id]: true });
        setSelectedPassages({ ...selectedPassages, [newSection.id]: 0 }); // ✅ Auto-select first passage
    };

    const updateSection = (index, newSection) => {
        setSections(sections.map((s, i) => (i === index ? newSection : s)));
    };

    const removeSection = (index, sectionId) => {
        setSections(sections.filter((_, i) => i !== index));
        const updatedShowSections = { ...showSections };
        delete updatedShowSections[sectionId];
        setShowSections(updatedShowSections);

        const updatedSelectedPassages = { ...selectedPassages };
        delete updatedSelectedPassages[sectionId];
        setSelectedPassages(updatedSelectedPassages);
    };

    const toggleSectionVisibility = (sectionId) => {
        setShowSections(prevState => ({
            ...prevState,
            [sectionId]: !prevState[sectionId]
        }));

        if (!showSections[sectionId]) {
            setSelectedPassages(prevState => ({
                ...prevState,
                [sectionId]: 0 // ✅ Auto-select first passage when expanding
            }));
        }
    };

    return (
        <div>
            <h1>Online Test Builder</h1>

            {sections.map((section, index) => (
                <div key={section.id} className="mb-3 border p-3">
                    <button 
                        className='btn btn-info mb-2'
                        onClick={() => toggleSectionVisibility(section.id)}
                    >
                        {showSections[section.id] ? "Hide Section" : "Show Section"}
                    </button>

                    {showSections[section.id] && (
                        <Section
                            section={section}
                            index={index}
                            updateSection={updateSection}
                            removeSection={() => removeSection(index, section.id)}
                            selectedPassageIndex={selectedPassages[section.id]} // ✅ Pass selected passage
                            setSelectedPassageIndex={(value) => 
                                setSelectedPassages(prevState => ({
                                    ...prevState,
                                    [section.id]: value
                                }))
                            } 
                        />
                    )}
                </div>
            ))}

            <button className='btn btn-secondary' onClick={addSection}>Add Section</button>
        </div>
    );
};

export default TestBuilder;
